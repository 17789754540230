<template>
  <div>
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :data-items="kgm_computedGridItems(preparedNotes)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(preparedNotes)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        style="height: auto;"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="nameLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }`"
              class="alt-primary-color"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <template
          slot="dateTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span v-tooltip="dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
          </td>
        </template>
        <template
          slot="preTemplate"
          slot-scope="{props}"
        >
          <td
            :class="props.className"
            v-html="kgm_getNestedValue(props.field, props.dataItem)"
          />
        </template>
        <template
          slot="detailTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-sm btn-primary"
                @click="openDetailSidebar(props.dataItem.id)"
              >
                Read
              </button>
              <button
                class="btn btn-sm btn-success"
                @click="openReplySidebar(props.dataItem)"
              >
                Reply
              </button>
            </div>
          </td>
        </template>
        <div
          slot="messageFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="messageFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a message"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('messageFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="typeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('typeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="readTemplate"
          slot-scope="{props}"
        >
          <td>
            <font-awesome-icon
              v-if="props.dataItem.read"
              class="gray"
              icon="envelope-open"
            />
            <font-awesome-icon
              v-else
              icon="envelope"
            />
          </td>
        </template>
        <div
          slot="userFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="userFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a user"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('userFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="nameLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }`"
              class="alt-primary-color"
              target="_blank"
            >
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </router-link>
          </td>
        </template>
      </Grid>

      <Sidebar
        :show-sidebar="detailSidebarIsVisible"
        :sidebar-width="600"
        @close="closeDetailSidebar"
      >
        <template v-if="detailSidebarIndex != null">
          <table class="defaultTable">
            <colgroup>
              <col width="33.333%">
              <col width="66.666%">
            </colgroup>
            <tbody>
              <tr>
                <th class="bold">
                  Created
                </th>
                <td data-placement="top">
                  <span v-tooltip="dateTime_fromNow(getDetailSidebarComment.created)">{{ getDetailSidebarComment.created | dateTime_dateTime }}</span>
                </td>
              </tr>
              <tr>
                <th class="bold">
                  Installation
                </th>
                <td data-placement="top">
                  {{ getDetailSidebarComment.installationName }}
                </td>
              </tr>
              <tr>
                <th class="bold">
                  Message
                </th>
                <td
                  class="xmp"
                  data-placement="top"
                  v-html="getDetailSidebarComment.message"
                />
              </tr>
              <tr>
                <th class="bold">
                  Type
                </th>
                <td data-placement="top">
                  {{ getDetailSidebarComment.type }}
                </td>
              </tr>
              <tr>
                <th class="bold">
                  Recipient
                </th>
                <td data-placement="top">
                  {{ getDetailSidebarComment.recipient }}
                </td>
              </tr>
              <tr>
                <th class="bold">
                  From
                </th>
                <td data-placement="top">
                  {{ getDetailSidebarComment.userName }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </Sidebar>
      <Sidebar
        v-if="replySidebarIsVisible"
        :show-sidebar="replySidebarIsVisible"
        @close="closeReplySidebar"
      >
        <InstallationNote
          :installation-id="replyInstallationId"
          :reply-recipient="replyRecipient"
          @close="closeReplySidebar"
        />
      </Sidebar>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { mapGetters } from 'vuex';

export default {
  name: "Inbox",
  components: {
    Sidebar,
    InstallationNote: () => import('@/components/Installation/InstallationNote.vue')
  },
  mixins: [
    dateTimeMixin,
    kendoGridMixin,
    authenticationMixin
  ],
  props: {
    noteId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      notes: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "message",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_sort: [
        {
          "field": "created",
          "dir": "desc"
        }
      ],
      kgm_columns: [
        {
          field: 'created',
          filterable: false,
          filter: 'date',
          title: 'Created',
          cell: 'dateTemplate',
          width: '110px'
        },
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: 'Installation',
          filterCell: "nameFilter",
          hideOn: ['xsDown'],
          width: '160px',
          cell: 'nameLink'
        },
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: 'Type',
          filterCell: "typeFilter",
          hideOn: ['mdDown'],
          width: '120px'
        },
        {
          field: 'message',
          filterable: true,
          filter: 'text',
          title: 'Message',
          cell: 'preTemplate',
          filterCell: "messageFilter",
        },
        {
          field: 'userName',
          filterable: true,
          filter: 'text',
          title: this.$t('from'),
          filterCell: "userFilter",
          hideOn: ['mdDown'],
          width: '120px'
        },
        {
          field: 'read',
          filterable: true,
          filter: 'boolean',
          title: 'Read',
          cell: 'readTemplate',
          width: '90px',
          hideOn: ['smDown'],
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'detailTemplate',
          width: '130px'
        }
      ],
      detailSidebarIsVisible: false,
      replySidebarIsVisible: false,
      detailSidebarIndex: null,
      replyInstallationId: null,
      replyRecipient: null
    }
  },
  computed: {
    ...mapGetters([
      'store_getReadNoteLength'
    ]),
    getDetailSidebarComment () {
      return this.notes.find((comment, index) => index == this.detailSidebarIndex);
    },
    preparedNotes: function () {
      if (this.notes == null) {
        return;
      }
      return this.notes.map(function (o) { return {
        created: o.created,
        installationId: o.installationId,
        installationName: o.installationName,
        message: o.message ? o.message.replace(/\n/g, "<br />") : '',
        userName: o.userName,
        type: o.type,
        read: o.read,
        id: o.id
      }});
    },
    commentsAvailable: function () {
      if (typeof this.historyComments != 'undefined' && this.historyComments.length > 0) return true;
      return false;
    }
  },
  created () {
    this.getNotes();
  },
  methods: {
    closeDetailSidebar () {
      this.detailSidebarIsVisible = false;
      this.detailSidebarIndex = null;
    },
    closeReplySidebar () {
      this.replySidebarIsVisible = false;
      this.replyInstallationId = null;
      this.replyRecipient = null;
    },
    openDetailSidebar (value) {
      this.notes.forEach((comment, index) => {
        if (comment.id == value) {
          this.detailSidebarIndex = index;
          if(!comment.read) {
            this.setRead(comment.id);
            comment.read = true;
            if(this.store_getReadNoteLength && this.store_getReadNoteLength > 0) {
              this.$store.commit('store_setReadNoteLength', this.store_getReadNoteLength - 1);
            }
          }
          return true;
        }
        return false;
      });
      this.detailSidebarIsVisible = true;
    },
    openReplySidebar (val) {
      this.replyInstallationId = val.installationId;
      this.replyRecipient = val.userName;
      this.replySidebarIsVisible = true;
    },
     setRead (id) {
      this.axios.post(`/Installation/SetNoteReadFlag?id=${ id }`);
    },
    getNotes () {
      this.loading = true;
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.axios.get(`/Installation/GetInstallationCommentsForUser?email=${email}&isNotification=false`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.notes = response.data;
          if(this.noteId) {
            this.openDetailSidebar(parseInt(this.noteId));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.xmp{
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
